import React from "react";
import { useSelector } from "react-redux";
import quote from "../../../../assets/images/quote.png";

const TestimonialCard = (props) => {
  const { testimonial, id } = props;
  const selectedLanguage = useSelector((state) => state?.language?.language?.selectedLanguage);
  return (
    <>
      <div className="p-4" id={id}>
        <div className="w-full flex justify-between">
          <div className="flex  justify-between items-center">
            <img
              src={testimonial?.photo}
              alt=""
              className="w-10 h-10 rounded-full mx-auto mb-4 lg:w-20 lg:h-20"
            />
            <h2 className=" font-medium text-lg text-center font-outfit text-[#003A7B]  pt-5 pl-3">
              {selectedLanguage?.code === "EN" ? (
                <>{testimonial.name}</>
              ) : (
                <>{testimonial?.translation?.guj?.name}</>
              )}
            </h2>
          </div>
          <div className="">
            <img src={quote} className="md:w-full w-1/2" alt="" />
          </div>
        </div>
        {selectedLanguage?.code === "EN" ? (
          <>
            <p className="text-base text-[#003A7B] font-outfit  text-left">
              {testimonial?.message}
            </p>
          </>
        ) : (
          <>
            <p className="text-base text-[#003A7B] font-outfit  mb-4 text-left">
              {testimonial?.translation?.guj?.message}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default TestimonialCard;
