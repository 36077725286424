import { createSlice } from '@reduxjs/toolkit'

export const affiliateSlice = createSlice({
    name: 'affiliateStore',
    initialState: {
        affiliateCode: "", 
    },
    reducers: {
        setAffiliateCode: (state, action) => {
            state.affiliateCode = action.payload
        }
    },
})

export const {setAffiliateCode} = affiliateSlice.actions

export default affiliateSlice.reducer