import axiosInstance from "./axiosInstance";

export function singUp(data) {
    return axiosInstance.post("client/add-new-client", data);
}

export function singIn(data) {
    return axiosInstance.post("client/client-sign-in", data);
}

export function forgotPassword(data,query) {
    return axiosInstance.post(`client/forgot-client-password?lang=${query}`, data);
}

export function resetPassword(data) {
    return axiosInstance.put(`client/set-new-client-password`, data);
}

export function getUserData(data) {
    return axiosInstance.get(`client/get-client/${data}`);
}
