import React from "react";
import cardiology from "../../../assets/images/Cardiology-img.png";

const ServiceCard = ({...props}) => {
  const { key, title, image, onClickCard } = props

  return (
    <>
      <div
        key={key}
        className="flex flex-wrap justify-center gap-10  cursor-pointer"
        onClick={onClickCard}
      >
        <div className="relative">
          <div>
            <img src={image||cardiology} alt="service Img" />
          </div>
          <div className="absolute bottom-4 left-4 font-syne text-2xl text-[#fff] font-semibold">
            <span>{title}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
