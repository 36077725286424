import React from "react";
import { AUTH_PREFIX_PATH } from "constants/route.constant";

const userAuthRoute = [
  {
    key: "user-signIn",
    path: `${AUTH_PREFIX_PATH}/sign-in`,
    component: React.lazy(() => import("../../views/auth/signIn")),
    authority: [],
    header: false,
    footer: false,
  },
  {
    key: "user-signUp",
    path: `${AUTH_PREFIX_PATH}/sign-up`,
    component: React.lazy(() => import("../../views/auth/signUp")),
    authority: [],
    header: false,
    footer: false,
  },
  {
    key: "user-forgotPassword",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import("../../views/auth/forgotPassword")),
    authority: [],
    header: false,
    footer: false,
  },
  {
    key: "user-resetPassword",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() => import("../../views/auth/resetPassword")),
    authority: [],
    header: false,
    footer: false,
  },
];

export default userAuthRoute;