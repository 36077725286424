import React from "react";

import TestimonialSlider from "./components/testimonialSlider";
import { SectionTitle } from "components/shared";
// import { useTranslation } from "react-i18next";

const Testimonial = ({ title="", breakpoints, testimonials }) => {
    // const { t } = useTranslation();

    // console.log("testimonials", testimonials);
    return (
        <>
            <div>
                {title && (<>
                    <div className="pt-10 lg:pt-20">
                        <SectionTitle title={title} />
                    </div>
                </>)}
                <div className="pt-6 lg:pt-10">
                    <TestimonialSlider
                        testimonials={testimonials}
                        breakpoints={breakpoints}
                    />
                </div>
            </div>
        </>
    );
};

export default Testimonial;


