import axios from "axios";
import { BACKEND_BASE_URL } from "./baseurl";
import useEncryption from "../utils/hooks/useEncryption";
const BACKEND_URL = BACKEND_BASE_URL;
const axiosInstance = axios.create({ baseURL: BACKEND_URL });

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    if (config.url !== "client/add-new-client" || config.url !== "client/client-sign-in") {

      const user = localStorage.getItem("user");
      const authData = JSON.parse(user).auth;
      const token = JSON.parse(authData).session.token;

      config.headers = {
        Authorization: `${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data;",
      };
    }
    // Do something before request is sent
    const formData = new FormData();
    if (config.data) {
      if (process.env.REACT_APP_PROJECT_MODE === "development") {
        if (config?.data?.file) {
          formData.append("file", config?.data?.file);
          delete config?.data?.file;
        }
        if (config?.data?.files) {
          formData.append("files", config?.data?.files);
          delete config?.data?.files;
        }
        formData.append("data", JSON.stringify(config.data));
      } else {
        if (config?.data?.path && config?.data?.file) {
          formData.append("file", config?.data?.file);
          delete config?.data?.file;
        }
        if (config?.data?.files) {
          formData.append("files", config?.data?.files);
          delete config?.data?.files;
        }
        // formData.append("data", await config.data);
        formData.append("data", await useEncryption.encryptData(config.data));
      }
    }
    config.data = formData;
    // console.log("backend ma mokal vanu return ", config.data)
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = response;
    if (process.env.REACT_APP_PROJECT_MODE === "development") {
      return result.data;
    } else {
      return await useEncryption.decryptData(result.data);
    }
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
