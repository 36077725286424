import React from "react";

const BurgerSvg = ({onClick}) => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9 17.009C18.9 17.56 18.3823 18 17.8027 18H14.6527C14.0742 18 13.65 17.56 13.65 17.009V14.009C13.65 13.458 14.0742 13 14.6527 13H17.8027C18.3823 13 18.9 13.458 18.9 14.009V17.009ZM17.8027 11H14.6527C12.9129 11 11.55 12.352 11.55 14.009V17.009C11.55 18.666 12.9129 20 14.6527 20H17.8027C19.5426 20 21 18.666 21 17.009V14.009C21 12.352 19.5426 11 17.8027 11ZM7.35 17.009C7.35 17.56 6.83235 18 6.25275 18H3.10275C2.5242 18 2.1 17.56 2.1 17.009V14.009C2.1 13.458 2.5242 13 3.10275 13H6.25275C6.83235 13 7.35 13.458 7.35 14.009V17.009ZM6.25275 11H3.10275C1.3629 11 0 12.352 0 14.009V17.009C0 18.666 1.3629 20 3.10275 20H6.25275C7.9926 20 9.45 18.666 9.45 17.009V14.009C9.45 12.352 7.9926 11 6.25275 11ZM18.9 6.009C18.9 6.56 18.3823 7 17.8027 7H14.6527C14.0742 7 13.65 6.56 13.65 6.009V3.009C13.65 2.458 14.0742 2 14.6527 2H17.8027C18.3823 2 18.9 2.458 18.9 3.009V6.009ZM17.8027 0H14.6527C12.9129 0 11.55 1.352 11.55 3.009V6.009C11.55 7.666 12.9129 9 14.6527 9H17.8027C19.5426 9 21 7.666 21 6.009V3.009C21 1.352 19.5426 0 17.8027 0ZM7.35 6.009C7.35 6.56 6.83235 7 6.25275 7H3.10275C2.5242 7 2.1 6.56 2.1 6.009V3.009C2.1 2.458 2.5242 2 3.10275 2H6.25275C6.83235 2 7.35 2.458 7.35 3.009V6.009ZM6.25275 0H3.10275C1.3629 0 0 1.352 0 3.009V6.009C0 7.666 1.3629 9 3.10275 9H6.25275C7.9926 9 9.45 7.666 9.45 6.009V3.009C9.45 1.352 7.9926 0 6.25275 0Z"
          fill="#003A7B"
        />
      </svg>
    </>
  );
};

export default BurgerSvg;
