const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/",
  unAuthenticatedEntryPath: "/",
  tourPath: "/auth/sign-in",
  enableMock: true,
  pagePerData: 10,
};

export default appConfig;
