import React from "react";

import { PAGES_PREFIX_PATH } from "../../constants/route.constant";
import { USER } from "../../constants/roles.constant";

const pagesRoute = [
  {
    key: "pages.accessDenied",
    path: `${PAGES_PREFIX_PATH}/access-denied`,
    component: React.lazy(() => import("../../pages/accessDeniedPage")),
    authority: [USER],
  },
];

export default pagesRoute;
