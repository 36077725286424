import React, { memo,useMemo,lazy,Suspense,useState ,useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import userAuthRoute from "configs/routes.config/userAuthRoute";
import { Spinner } from "components/ui";

const Layout = () => {
  const location = useLocation();
  const [isAuthRoute, setIsAuthRoute] = useState(false);

  useLayoutEffect(() => {
    // console.log('Current Pathname:', location.pathname);
    const currentAuthRoute = userAuthRoute.find(route => route.path === location.pathname);
    // console.log('Current Auth Route:', currentAuthRoute);
    setIsAuthRoute(!!currentAuthRoute);
  }, [location.pathname]);

  // set layout based on module  
  const AppLayout = useMemo(() => {
    if (isAuthRoute) {
      return lazy(() => import("./authLayout"));
    }
    return lazy(() => import("./classicLayout"));
  }, [isAuthRoute]);
  return (
    <>
      <Suspense
        fallback={
          <div className="h-[100vh]  flex justify-center items-center">
            <Spinner size="3.25rem" />
          </div>
        }
      >
        <AppLayout />
      </Suspense>
    </>
  );
};

export default memo(Layout);
