import React from "react";
import { USER } from "../../constants/roles.constant";

const userProtectedRoute = [
  {
    key: "client-details",
    path: `/profile-details`,
    component: React.lazy(() => import("views/profileInfo")),
    authority: [USER],
    header: true,
    footer: true,
  },

  {
    key: "client-application-process",
    path: `/application-process`,
    component: React.lazy(() => import("views/applicationProcess")),
    authority: [USER],
    header: true,
    footer: true,
  },
  {
    key: "client-payment-success",
    path: `/payment-success`,
    component: React.lazy(() => import("views/payment/paymentSuccess")),
    authority: [],
    header: true,
    footer: true,
  },
  {
    key: "client-payment-failed",
    path: `/payment-failed`,
    component: React.lazy(() => import("views/payment/paymentFailed")),
    authority: [],
    header: true,
    footer: true,
  },
];

export default userProtectedRoute;